<template>
	<a :href="href" class="quiz-card-wrapper">
		<div class="quiz-card">
			<span class="tag" v-if="badgeName" :style="{backgroundColor: badgeColor}">{{ badgeName }}</span>
			<div class="clip-photo">
				<img v-if="quizImage" :src="$cdn(quizImage)" :alt="title" />
				<span class="icon"><i :class="iconName"></i></span>
			</div>
			<div class="quiz-titles">
				<h1>{{ title }}</h1>
				<h2 v-if="subtitle" v-html="subtitle">{{ subtitle }}</h2>
			</div>
		</div>
	</a>
</template>

<script>
    export default {
        name: 'QuizCard',
        props: {
            badgeName: String,
            badgeColor: String,
            quizImage: String,
            iconName: String,
            title: String,
            subtitle: String,
            href: String
        }
    }
</script>
<template>
    <div class="notify-me-form" :style="style" @click.prevent="">
        <div class="has-text-right">
            <img class="close" src="/images/icons/x.svg" @click.prevent="cancel"/>
        </div>
        <p>Notify me by email when {{productName}} becomes available.</p>
        <div class="field has-addons">
            <div class="control is-expanded">
                <input type="text" class="input is-large" v-model="formData.emailAddress" placeholder="Email address" @keydown.enter.prevent="notifyMe"/>
            </div>
            <button class="button is-large is-primary" :class="{'is-loading' : saving}" :disabled="!formData.emailAddress" @click.prevent="notifyMe">Send</button>
        </div>
        <div><span class="is-danger" v-if="formData.error">{{formData.error}}</span></div>
        <div class="has-text-right">
            <a @click.prevent="cancel">Cancel</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BackorderNotificationForm',


        props: {
            productId: {required : true},
            productName: {required : true},
            emailAddress: {default : null}
        },

        data() {
            return {
                formData : this.initForm(),
                saving : false,
                top: 0
            };
        },

        methods: {

            initForm() {
                return {
                    emailAddress : this.$props.emailAddress,
                    error : null
                }
            },

            notifyMe() {
                this.saving = true;
                axios.post(`/products/${this.$props.productId}/notify-me`, { email : this.formData.emailAddress })
                    .then(response => {
                        this.$buefy.notification.open({ duration: 5000, type: 'is-success', position: 'is-bottom-right', message: `Thank you! We will notify you once ${this.$props.productName} is available.` })
                        this.formData = this.initForm();
                        this.saving = false;
                        this.$emit('done');
                    })
                    .catch(error => {
                        if(error.response) {
                            this.formData.error = error.response.data.error;
                        }
                        else {
                            this.$buefy.notification.open({ duration: 5000, type: 'is-danger', position: 'is-bottom-right', message: "We're sorry, an error occurred. Please try again." })
                            this.formData = this.initForm();
                            this.$emit('done');
                        }
                        this.saving = false;
                    });
            },

            cancel() {
                this.$emit('cancel');
            }
        },

        computed: {
            style() {
                return {
                    top: `${this.top}px`
                };
            }
        }
    };
</script>

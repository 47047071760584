<template>
  <div class="quiz-questions">
    <a name="top"></a>
    <aura-header
        color1="#AA53D0"
        color2="#9712D0"
        static-failover-image="cdn://images/components/quizzes/rectangle-2@2x.jpg">
      <div class="banner container flex-column-middle-center" :class="{'entering': entering, 'leaving': leaving}">
        <h1 tabindex="0" class="title is-1 has-transition" ref="currentHeading">{{ currentHeading }}</h1>
      </div>
    </aura-header>
    <div class="has-text-centered">
      <div class="intro" v-if="onStep(steps.INTRO)" :class="{'entering': entering, 'leaving': leaving}">
        <div class="image">
          <div class="background">
            <img alt="" role="presentation" :src="$cdn('/images/pages/quizzes/bg-card.png')"/>
          </div>
          <div class="foreground">
            <img alt="" role="presentation" :src="$cdn(quiz.mediaUrl)"/>
          </div>
          <div class="icon1">
            <b-icon pack="fas" icon="clipboard-list-check"></b-icon>
          </div>
          <div class="icon2">
            <b-icon pack="fas" icon="check"></b-icon>
          </div>
        </div>

        <div class="description">{{ quiz.description }}</div>

        <a @click="next()" href="#" class="button is-black">Take the quiz</a>
        <div class="more"><a href="/quizzes">or, explore more quizzes</a></div>
      </div>
      <div class="email-results" v-else-if="onStep(steps.EMAIL_RESULTS)">
        <div class="container description">
          Enter your email address below. This step is optional.
        </div>
        <div class="container email-container">
          <fieldify label="Email address" label-id="deliveryEmail" :errors="errors" error-field="deliveryEmail" v-floating-label>
            <input class="input is-large" name="deliveryEmail" type="text" aria-labelledby="deliveryEmail" aria-required="false" v-model.lazy="$v.deliveryEmail.$model"></input>
          </fieldify>
          <p>We will only send you the quiz results and any<br>information associated with your results.</p>
        </div>
        <div class="continue">
          <button class="button is-primary" @click.prevent="next()">
            See Results
            <b-icon pack="fas" size="is-small" icon="arrow-right"></b-icon>
          </button>
        </div>
      </div>
      <div class="analyzing" v-else-if="onStep(steps.ANALYZING)" :class="{'entering': entering, 'leaving': leaving}">
        <div class="image">
          <div class="background">
            <img alt="" role="presentation" :src="$cdn('/images/pages/quizzes/bg-card.png')"/>
          </div>
          <div class="foreground spinner">
            <b-icon pack="fad" size="is-small" icon="spinner-third" aria-label="Analyzing your results" aria-live=â€�assertiveâ€� ></b-icon>
          </div>
          <div class="icon1">
            <b-icon pack="fas" icon="clipboard-list-check"></b-icon>
          </div>
          <div class="icon2">
            <b-icon pack="fas" icon="check"></b-icon>
          </div>
        </div>
      </div>
      <div class="walkthrough" v-else-if="onStep(steps.QUESTIONS)" :class="{'entering': entering, 'leaving': leaving}">
        <div class="container instructions" v-if="!isSingleSelect">
          Select all that apply
        </div>
        <div class="container quiz-progress">
          <b-progress :value="progressPercent" show-value>
            <div class="tip-wrapper" :style="{left: progressPercent + '%'}">
              <b-tooltip :label="progressPercent + '%'" always></b-tooltip>
            </div>
          </b-progress>
        </div>
        <div class="answers container">
          <div class="columns is-multiline is-centered" :role="isSingleSelect ? 'radiogroup' : null" :aria-label="currentQuestion.text">
            <div class="column answer is-4 is-paddingless" v-for="answer in currentQuestion.answers" :class="{'chosen':answer===currentQuestion.chosen}">
              <b-radio aria-required="true" v-model="currentQuestion.chosen" :name="String(currentQuestion.id)" :native-value="[answer]" v-if="isSingleSelect">{{ answer.text }}</b-radio>
              <b-checkbox v-model="currentQuestion.chosen" :name="String(currentQuestion.id)" :native-value="answer" v-else>{{ answer.text }}</b-checkbox>
            </div>
          </div>
        </div>
        <div class="continue">
          <a href="#" class="button is-black" :disabled="preventNextStep" @click.prevent="next()">
            Continue
            <b-icon pack="fas" size="is-small" icon="arrow-right"></b-icon>
          </a>
        </div>
        <div class="back-button" v-if="canGoBack">
          <a href="#" @click.prevent="back()">Back</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddToCartButton from "./../AddToCartButton.vue";
import VueRouter from "vue-router";
import AuraHeader from "../features/AuraHeader.vue";
import VueScrollTo from "vue-scrollto";
import Fieldify from "~/components/Fieldify.vue";

import validationMixin from "~/mixins/validation-support";
const {email} = window.validators;

const router = new VueRouter({mode: "history"})

export default {
  name: "QuizQuestions",

  components: {AddToCartButton, AuraHeader, Fieldify},
  router,

  mixins: [validationMixin],

  props: {
    quiz: {type: Object},
    showEmailStep: {type: Boolean, default: false}
  },

  data() {
    return {
      loading: false,
      entering: false,
      leaving: false,
      questionIndex: 0,
      currentStep: 0,
      deliveryEmail: null,
      errors: {}
    };
  },

  validations() {
    return {
      deliveryEmail: {
        email
      },
    }
  },

  mounted() {
    this.transition();
  },

  methods: {
    transition() {
      return new Promise((resolve, reject) => {
        this.leaving = true;
        this.entering = false;
        setTimeout(() => {
          resolve();
          this.entering = true;
          this.leaving = false;
          setTimeout(() => {
            this.entering = false;
          }, 500)
        }, 500)
      })
    },

    back() {
      this.transition().then(() => {
        setTimeout(() => this.$refs.currentHeading?.focus(), 2000);
        this.questionIndex--;
        this.currentStep = this.steps.QUESTIONS;
      });
    },

    next() {
      setTimeout(() => this.$refs.currentHeading?.focus(), 2000);
      VueScrollTo.scrollTo("main");

      if (this.onStep(this.steps.INTRO)) {
        this.transition().then(() => {
          this.currentStep = this.steps.QUESTIONS;
        });
      } else if (this.onStep(this.steps.EMAIL_RESULTS)) {
        // Validate the email
        this.$v.$reset();
        this.$v.$touch();
        // Complete if valid OR empty
        if (!this.$v.$invalid || this.deliveryEmail === null || this.deliveryEmail.length === 0) {
          this.onComplete();
        }
      } else {
        this.transition().then(() => {

          // Look at the outcome to figure out where to send
          let outcome = null;

          if (this.currentQuestion.chosen.constructor === Array) {
            // Multichoice, find the first outcome and direct to that
            outcome = this.currentQuestion.chosen[0].outcome;
          } else {
            // Object, get the outcome directly
            outcome = this.currentQuestion.chosen.outcome;
          }

          // If it's a product, we're done, otherwise find the question to direct to
          if (outcome.type === "PRODUCT") {
            this.currentStep = this.steps.ANALYZING;
            axios.get(`/products/by-ids?ids=${outcome.entityId}`)
                .then(response => {
                  window.dataLayer.push({"event": "GAEvent", "eventCategory": "Quiz", "eventAction": "Completion", "eventLabel": this.quiz.name});
                  setTimeout(_ => {
                    this.quiz.recommendedProduct = response.data[0];

                    // Either move to the email step or complete
                    if (this.showEmailStep) {
                      this.transition().then(() => {
                        this.currentStep = this.steps.EMAIL_RESULTS;
                      });
                    }
                    else {
                      this.onComplete();
                    }
                  }, 2000);
                });
          } else {
            this.quiz.questions.forEach((question, i) => {
              if (question.id === outcome.entityId) {
                this.questionIndex = i;
                history.pushState(null, "", `${this.$route.path}?i=${this.onStep(this.steps.INTRO)}&s=${this.questionIndex}`);
              }
            });
          }
        })
      }
    },

    save() {
      let request = JSON.parse(JSON.stringify(this.quiz));
      request.recommendedProduct = this.quiz.recommendedProduct.id;
      request.deliveryEmail = this.showEmailStep ? this.deliveryEmail : null;
      axios.post("/quiz", request);
    },

    onComplete() {
      this.save();
      this.currentStep = this.steps.COMPLETE;
      this.$emit('on-complete');
    },
    
    onStep(step) {
      return this.currentStep === step;
    }
  },

  computed: {
    steps() {
      return {
        INTRO: 0,
        QUESTIONS: 1,
        ANALYZING: 2,
        EMAIL_RESULTS: 3,
        COMPLETE: 4
      }
    },

    currentHeading() {
      switch (this.currentStep) {
        case this.steps.INTRO:
          return this.quiz.name;
        case this.steps.EMAIL_RESULTS:
          return "Want your results sent to you?";
        case this.steps.ANALYZING:
          return "Analyzing your results...";
        default:
          return this.currentQuestion.text;
      }
    },

    currentQuestion() {
      return this.quiz && this.quiz.questions.length > 0 ? this.quiz.questions[this.questionIndex] : {chosen: []};
    },

    isSingleSelect() {
      return this.currentQuestion && this.currentQuestion.type === "RADIO";
    },

    preventNextStep() {
      return this.currentQuestion.chosen.length === 0;
    },

    canGoBack() {
      return this.questionIndex > 0;
    },

    progressPercent() {
      return this.onStep(this.steps.COMPLETE) ? 100
          : Math.round(this.questionIndex / this.quiz.questions.length * 100);
    }
  }
}
</script>